import { ExpandIcon, PanoGroupIcon, PhotoGroupIcon, PopupIcon } from '@/components/IconButton';
import {
  Panorama,
  Photo,
  Photo2D,
  setViewer2DSettings,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@skand/ui';
import { useState } from 'react';
import { Vector2 } from 'three';

export interface PhotoContextMenuProps {
  photo: Photo;
  position: Vector2;
}

export const PhotoContextMenu = ({ photo, position }: PhotoContextMenuProps) => {
  const loadingMutex = useViewer(state => state.loadingMutex);

  const [isView3DTooltip, setIsView3DTooltip] = useState(false);
  const [isFullScreenTooltip, setIsFullScreenTooltip] = useState(false);
  const [isPopupTooltip, setIsPopupTooltip] = useState(false);

  const handleView3D = async () => {
    setViewer2DSettings({ showFullScreen: false });
    if (!loadingMutex && photo) {
      if (photo.type === 'photo2D') {
        stopPhotoOverlay();
        stopPanoramaView();
        await startPhotoOverlay(photo as Photo2D);
      } else if (photo.type === 'panorama') {
        stopPhotoOverlay();
        stopPanoramaView();
        await startPanoramaWalkthrough(photo as Panorama);
      }
    }
  };

  const handleEnableFullScreen = async () => {
    setViewer2DSettings({ showFullScreen: true });
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (photo.type === 'photo2D') {
        await startPhotoOverlay(photo);
      }
    }
  };

  const handlePopup = async () => {
    useViewer.setState(prev => {
      const popupPhotos = new Set(prev.popupPhotos);
      popupPhotos.add(photo);
      return { popupPhotos };
    });
  };

  return (
    <div
      className={cn(
        'flex',
        'flex-row',
        'items-center',
        'absolute',
        'rounded-1',
        'w-auto',
        'p-2',
        'gap-2',
        'bg-neutral-100',
        'pointer-events-auto',
        'translate-x--50%',
        'translate-y--150%',
      )}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      {photo.type === 'photo2D' && (
        <button
          className={cn('b-none', 'bg-neutral-100', 'cursor-pointer', 'w-16px', 'h-16px')}
          onClick={handleView3D}
          onMouseEnter={() => setIsView3DTooltip(true)}
          onMouseLeave={() => setIsView3DTooltip(false)}
        >
          <PhotoGroupIcon
            className={cn('h-full', 'w-full', 'color-neutral-800', 'hover:color-neutral-500')}
          />
          {isView3DTooltip && (
            <span
              className={cn(
                'absolute',
                'translate-x--15px',
                'translate-y--150%',
                'w-auto',
                'bg-neutral-800',
                'text-neutral-100',
                'typo-text-small',
                'px-2',
                'py-1',
                'rounded-1',
                'gap-10px',
                'whitespace-nowrap',
              )}
            >
              Open in 3D Image Viewer
            </span>
          )}
        </button>
      )}

      {photo.type === 'photo2D' && (
        <button
          className={cn('b-none', 'bg-neutral-100', 'cursor-pointer', 'w-16px', 'h-16px')}
          onClick={handleEnableFullScreen}
          onMouseEnter={() => setIsFullScreenTooltip(true)}
          onMouseLeave={() => setIsFullScreenTooltip(false)}
        >
          <ExpandIcon
            className={cn('h-full', 'w-full', 'color-neutral-800', 'hover:color-neutral-500')}
          />
          {isFullScreenTooltip && (
            <span
              className={cn(
                'absolute',
                'translate-x--15px',
                'translate-y--150%',
                'w-auto',
                'bg-neutral-800',
                'text-neutral-100',
                'typo-text-small',
                'px-2',
                'py-1',
                'rounded-1',
                'gap-10px',
                'whitespace-nowrap',
              )}
            >
              Open in 2D full screen
            </span>
          )}
        </button>
      )}

      {photo.type === 'panorama' && (
        <button
          className={cn('b-none', 'bg-neutral-100', 'cursor-pointer', 'w-16px', 'h-16px')}
          onClick={handleView3D}
          onMouseEnter={() => setIsView3DTooltip(true)}
          onMouseLeave={() => setIsView3DTooltip(false)}
        >
          <PanoGroupIcon
            className={cn('h-full', 'w-full', 'color-neutral-800', 'hover:color-neutral-500')}
          />
          {isView3DTooltip && (
            <span
              className={cn(
                'absolute',
                'translate-x--15px',
                'translate-y--150%',
                'w-auto',
                'bg-neutral-800',
                'text-neutral-100',
                'typo-text-small',
                'px-2',
                'py-1',
                'rounded-1',
                'gap-10px',
                'whitespace-nowrap',
              )}
            >
              Enter panoramic walkthrough
            </span>
          )}
        </button>
      )}

      <button
        className={cn('b-none', 'bg-neutral-100', 'cursor-pointer', 'w-16px', 'h-16px')}
        onClick={handlePopup}
        onMouseEnter={() => setIsPopupTooltip(true)}
        onMouseLeave={() => setIsPopupTooltip(false)}
      >
        <PopupIcon
          className={cn('h-full', 'w-full', 'color-neutral-800', 'hover:color-neutral-500')}
        />
        {isPopupTooltip && (
          <span
            className={cn(
              'absolute',
              'translate-x--15px',
              'translate-y--150%',
              'w-auto',
              'bg-neutral-800',
              'text-neutral-100',
              'typo-text-small',
              'px-2',
              'py-1',
              'rounded-1',
              'gap-10px',
              'whitespace-nowrap',
            )}
          >
            Open in pop-out
          </span>
        )}
      </button>
    </div>
  );
};
