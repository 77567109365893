import { BranchTreeNode, FileTreeNode, FolderTreeNode } from '@/domains/download/DownloadTreeNode';
import {
  ListFilesByFileIdsForDownloadQuery,
  ListFilesByFileIdsForDownloadQueryVariables,
  ListSystemNodesByParentNodeIdForDownloadQuery,
  ListSystemNodesByParentNodeIdForDownloadQueryVariables,
  ListSystemNodesBySystemNodeIdsForDownloadQuery,
  ListSystemNodesBySystemNodeIdsForDownloadQueryVariables,
  SystemNodeKindInput,
} from '@/graphql/codegen/graphql';
import { IGqlOperation } from '@/graphql/operations/GqlOperation';
import { ListFilesByFileIdsForDownload } from '@/graphql/queries/ListFilesByFileIdsForDownload.gql';
import { ListSystemNodesByParentNodeIdForDownload } from '@/graphql/queries/ListSystemNodesByParentNodeIdForDownload.gql';
import { ListSystemNodesBySystemNodeIdsForDownload } from '@/graphql/queries/ListSystemNodesBySystemNodeIdsForDownload.gql';

type GqlOperations = {
  ListFilesByFileIdsForDownload: IGqlOperation<
    ListFilesByFileIdsForDownloadQueryVariables,
    ListFilesByFileIdsForDownloadQuery
  >;
  ListSystemNodesByParentNodeIdForDownload: IGqlOperation<
    ListSystemNodesByParentNodeIdForDownloadQueryVariables,
    ListSystemNodesByParentNodeIdForDownloadQuery
  >;
  ListSystemNodesBySystemNodeIdsForDownload: IGqlOperation<
    ListSystemNodesBySystemNodeIdsForDownloadQueryVariables,
    ListSystemNodesBySystemNodeIdsForDownloadQuery
  >;
};

export class BuildSystemNodeTreeForDownloadUseCase {
  private _gqlOperations: GqlOperations;

  constructor(
    gqlOperations = {
      ListFilesByFileIdsForDownload,
      ListSystemNodesByParentNodeIdForDownload,
      ListSystemNodesBySystemNodeIdsForDownload,
    },
  ) {
    this._gqlOperations = gqlOperations;
  }

  async execute(systemNodeIds: string[], signal?: AbortSignal) {
    const rootTreeNode = new BranchTreeNode();
    await this._buildSystemNodeTree(systemNodeIds, rootTreeNode, signal);
    return rootTreeNode;
  }

  private async _buildSystemNodeTree(
    systemNodeIds: string[],
    parentTreeNode: BranchTreeNode,
    signal?: AbortSignal,
  ) {
    let systemNodes: ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds'] = [];
    if (systemNodeIds.length !== 0) {
      const systemNodeQuery =
        await this._gqlOperations.ListSystemNodesBySystemNodeIdsForDownload.request(
          { systemNodeIds },
          { signal },
        );
      systemNodes = systemNodeQuery.findSystemNodesByIds ?? [];
    }

    const sourceNodeIds = systemNodes?.reduce((prev, curr) => {
      if (curr?.__typename === 'LinkNode' && curr.sourceNodeId) return [...prev, curr.sourceNodeId];
      return prev;
    }, [] as string[]);

    let sourceNodes: ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds'] = [];
    if (sourceNodeIds.length !== 0) {
      const sourceNodeQuery =
        await this._gqlOperations.ListSystemNodesBySystemNodeIdsForDownload.request(
          { systemNodeIds: sourceNodeIds },
          { signal },
        );
      sourceNodes = sourceNodeQuery.findSystemNodesByIds ?? [];
    }

    const fileIds = [...systemNodes, ...sourceNodes].reduce((prev, curr) => {
      if (curr?.__typename === 'FileNode' && curr.fileId) return [...prev, curr.fileId];
      return prev;
    }, [] as string[]);

    let files: ListFilesByFileIdsForDownloadQuery['filesByIds'] = [];
    if (fileIds.length !== 0) {
      const fileQuery = await this._gqlOperations.ListFilesByFileIdsForDownload.request(
        { fileIds },
        { signal },
      );
      files = fileQuery.filesByIds ?? [];
    }

    for (const systemNode of systemNodes) {
      if (!systemNode) continue;
      if (!systemNode.id) continue;
      if (!systemNode.name) continue;

      if (systemNode.__typename === 'FileNode') {
        this._handleFileNode(systemNode, files, parentTreeNode);
      } else if (systemNode.__typename === 'FolderNode') {
        await this._handleFolderNode(systemNode, parentTreeNode);
      } else if (systemNode.__typename === 'LinkNode') {
        const sourceNodeId = systemNode.sourceNodeId;
        const sourceNode = sourceNodes.find(n => n?.id === sourceNodeId);

        if (!sourceNode) continue;
        if (!sourceNode.id) continue;
        if (!sourceNode.name) continue;

        if (sourceNode.__typename === 'FileNode') {
          this._handleFileNode(sourceNode, files, parentTreeNode);
        } else if (sourceNode.__typename === 'FolderNode') {
          await this._handleFolderNode(sourceNode, parentTreeNode);
        }
      }
    }
  }

  private async _handleFileNode(
    systemNode: NonNullable<
      ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds']
    >[0],
    files: NonNullable<ListFilesByFileIdsForDownloadQuery['filesByIds']>,
    parentNode: BranchTreeNode,
  ) {
    if (!systemNode) return;
    if (!systemNode.id) return;
    if (!systemNode.name) return;
    if (systemNode.__typename !== 'FileNode') return;

    const fileId = systemNode.fileId;
    const file = files.find(f => f?.id === fileId);

    if (!file) return;
    if (!file.id) return;
    if (!file.signedGetObjectDownloadUrl) return;

    const relativePath =
      parentNode instanceof FolderTreeNode
        ? `${parentNode.relativePath}/${systemNode.name}`
        : systemNode.name;

    const treeNode = new FileTreeNode({
      fileDownloadUrl: file.signedGetObjectDownloadUrl,
      fileId: file.id,
      fileSize: file.storage?.size ?? undefined,
      nodeName: systemNode.name,
      relativePath,
      systemNodeId: systemNode.id,
    });

    parentNode.addChildNode(treeNode);
  }

  private async _handleFolderNode(
    systemNode: NonNullable<
      ListSystemNodesBySystemNodeIdsForDownloadQuery['findSystemNodesByIds']
    >[0],
    parentNode: BranchTreeNode,
  ) {
    if (!systemNode) return;
    if (!systemNode.id) return;
    if (!systemNode.name) return;
    if (systemNode.__typename !== 'FolderNode') return;

    const folderNodeQuery = await this._listSystemNodesByParentNodeId(systemNode.id);

    const childNodeIds = new Set<string>();
    for (const node of folderNodeQuery) {
      if (!node) continue;
      if (!node.id) continue;
      childNodeIds.add(node.id);
    }

    const relativePath =
      parentNode instanceof FolderTreeNode
        ? `${parentNode.relativePath}/${systemNode.name}`
        : systemNode.name;

    const treeNode = new FolderTreeNode({
      nodeName: systemNode.name,
      relativePath,
      systemNodeId: systemNode.id,
    });

    parentNode.addChildNode(treeNode);
    await this._buildSystemNodeTree([...childNodeIds], treeNode);
  }

  private async _listSystemNodesByParentNodeId(parentNodeId: string, signal?: AbortSignal) {
    const pageSize = 500;
    const nodeKinds = [
      SystemNodeKindInput.FileNode,
      SystemNodeKindInput.FolderNode,
      SystemNodeKindInput.LinkNode,
    ];

    let currentPageIndex = 0;
    let currentPageQuery =
      await this._gqlOperations.ListSystemNodesByParentNodeIdForDownload.request(
        {
          nodeKinds,
          parentNodeId,
          pageIndex: currentPageIndex,
          pageSize,
        },
        { signal },
      );
    const data = currentPageQuery?.listSystemNodesByParentNodeId?.data ?? [];

    while (
      currentPageQuery.listSystemNodesByParentNodeId &&
      currentPageQuery.listSystemNodesByParentNodeId.totalNumberOfPages !== null &&
      currentPageQuery.listSystemNodesByParentNodeId.totalNumberOfPages !== undefined &&
      currentPageQuery.listSystemNodesByParentNodeId.totalNumberOfPages > currentPageIndex + 1
    ) {
      currentPageIndex += 1;
      currentPageQuery = await this._gqlOperations.ListSystemNodesByParentNodeIdForDownload.request(
        {
          nodeKinds,
          parentNodeId,
          pageIndex: currentPageIndex,
          pageSize,
        },
        { signal },
      );
      data.push(...(currentPageQuery?.listSystemNodesByParentNodeId?.data ?? []));
    }

    return data;
  }
}
