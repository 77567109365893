import { gql } from './codegen';

export const ACCOUNT_BY_CONTEXT = gql(`
  query AccountByContext {
    accountByContext {
      id
    }
  }
`);

export const USER_BY_CONTEXT = gql(`
  query UserByContext {
    readUserByContext {
      id
      accountId
      name {
        first
        last
      }
      email
      isAdmin
      isActive
      isSuperAdmin
    }
  }
`);

export const LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS = gql(`
  query ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds($subjectId: String!, $objectIds: [String], $objectTypes: [PolicyObjectTypeInput], $actionTypes: [PolicyActionTypeInput]) {
    listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds(subjectId: $subjectId, objectIds: $objectIds, objectTypes: $objectTypes, actionTypes: $actionTypes) {
      accountId
      subjectId
      subjectType
      objectId
      objectType
      actionType
      createdAt
      updatedAt
    }
  }
`);

// Project
export const LIST_PROJECTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListProjectsByAccountContext {
    listProjectsByAccountContext {
      accountId
      address
      createdAt
      updatedAt
      description
      geoid
      id
      name
      projectGroupId
      longitude
      latitude
    }
  }
`);

export const FIND_PROJECT_BY_PROJECT_ID = gql(`
  query FindProjectByProjectId($projectId: ID!) {
    readProjectById(projectId: $projectId) {
      accountId
      address
      createdAt
      updatedAt
      description
      geoid
      id
      name
      projectGroupId
      longitude
      latitude
    }
  }
`);

// Project Group
export const LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT = gql(`
  query ListProjectGroupsByAccountContext {
    listProjectGroupsByAccountContext {
      accountId
      createdAt
      description
      id
      name
      updatedAt
    }
  }
`);

// Layer
export const LIST_LAYERS_BY_PROJECT_ID = gql(`
  query ListLayerByProjectId($projectId: ID!) {
    listLayersByProjectId(projectId: $projectId) {
      id
      name
      captureDate
      worldPosition
      worldRotation
      mainSceneEntityId
      status
      formatType
      defaultMaxHeight
      defaultMinHeight
    }
  }
`);

export const LIST_ANNOTATIONS_BY_ANNOTATION_GROUP_ID = gql(`
  query ListAnnotationsByAnnotationGroupId($annotationGroupId: ID!) {
    listAnnotationsByAnnotationGroupId(annotationGroupId: $annotationGroupId) {
      color
      groupId
      id
      name
      templateId
      annotationId
      createdByUserId
      createdAt
      annotation2d {
        imageFileId
        points {
          x
          y
        }
        shapeType
      }
      annotation3d {
        positions {
          x
          y
          z
        }
        rotations {
          w
          x
          y
          z
        }
        shapeType
      }

      fields {
        ... on AnnotationDateField {
          __typename
          fieldId
          end
          start
          type
        }
        ... on AnnotationFileField {
          __typename
          fieldId
          files {
            fileId
            name
          }
          type
        }
        ... on AnnotationImageField {
          __typename
          fieldId
          file {
            ... on ImageFile {
              __typename
              fileName
              id
              kind
              originalUrl
              thumbnailUrl
            }
          }
          fileId
          type
        }
        ... on AnnotationSelectField {
          __typename
          fieldId
          optionId
          type
        }
        ... on AnnotationTextField {
          __typename
          fieldId
          text
          type
        }
        ... on AnnotationUrlField {
          __typename
          fieldId
          type
          urls {
            url
            name
          }
        }
      }
    }
  }
`);

export const FIND_ACTIVE_VERSION_BY_ANNOTATION_ID = gql(`
  query FindActiveVersionByAnnotationId($annotationId: ID!) {
    findActiveVersionByAnnotationId(annotationId: $annotationId) {
      color
      groupId
      id
      name
      templateId
      annotationId
      annotation2d {
        imageFileId
        points {
          x
          y
        }
        shapeType
      }
      annotation3d {
        positions {
          x
          y
          z
        }
        rotations {
          w
          x
          y
          z
        }
        shapeType
      }

      fields {
        ... on AnnotationDateField {
          __typename
          fieldId
          end
          start
          type
        }
        ... on AnnotationFileField {
          __typename
          fieldId
          files {
            fileId
            name
          }
          type
        }
        ... on AnnotationImageField {
          __typename
          fieldId
          file {
            ... on ImageFile {
              __typename
              fileName
              id
              kind
              originalUrl
              thumbnailUrl
            }
          }
          fileId
          type
        }
        ... on AnnotationSelectField {
          __typename
          fieldId
          optionId
          type
        }
        ... on AnnotationTextField {
          __typename
          fieldId
          text
          type
        }
        ... on AnnotationUrlField {
          __typename
          fieldId
          type
          urls {
            url
            name
          }
        }
      }
    }
  }
`);

export const LIST_HISTORY_BY_ANNOTATION_ID = gql(`
  query ListHistoryByAnnotationId($annotationId: ID!) {
    listHistoryByAnnotationId(annotationId: $annotationId) {
      id
      isActiveVersion
      name
      createdAt
      createdByUserId
      annotationId
      color
      annotation2d {
        imageFileId
        points {
          x
          y
        }
        shapeType
      }
      annotation3d {
        positions {
          x
          y
          z
        }
        rotations {
          w
          x
          y
          z
        }
        shapeType
      }
      fields {
        ... on AnnotationDateField {
          __typename
          fieldId
          end
          name
          start
          type
        }
        ... on AnnotationFileField {
          __typename
          fieldId
          files {
            fileId
            name
          }
          name
          type
        }
        ... on AnnotationImageField {
          __typename
          fieldId
          file {
            ... on ImageFile {
              __typename
              fileName
              id
              kind
              originalUrl
              thumbnailUrl
            }
          }
          fileId
          name
          type
        }
        ... on AnnotationSelectField {
          __typename
          fieldId
          name
          type
          optionId
          value
        }
        ... on AnnotationTextField {
          __typename
          fieldId
          name
          text
          type
        }
        ... on AnnotationUrlField {
          __typename
          fieldId
          name
          type
          urls {
            url
            name
          }
        }
      }

    }
  }
`);

export const COUNT_ANNOTATIONS_BY_TEMPLATE_ID = gql(`
  query CountActiveAnnotationsByTemplateId($templateId: ID!, $filterDeleted: Boolean) {
    countActiveAnnotationsByTemplateId(templateId: $templateId, filterDeleted: $filterDeleted)
  }  
`);

// Annotation Template
export const LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID = gql(`
  query ListAnnotationTemplatesByProjectId($projectId: ID!) {
    listAnnotationTemplatesByProjectId(projectId: $projectId) {
      id
      accountId
      projectId
      level
      colorFromField
      name
      description
      fields {
        ... on AnnotationTemplateTextField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateFileField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateUrlField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateDateField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateSelectField {
          id
          name
          order
          type
          isDeleted
          isVisible
          options {
            color
            id
            value
          }
        }
        ... on AnnotationTemplateImageField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
      }
      createdBy
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
    }
  }
`);

export const LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT = gql(`
  query ListAnnotationTemplatesByAccountContext {
    listAnnotationTemplatesByAccountContext {
      id
      accountId
      projectId
      level
      colorFromField
      name
      description
      fields {
        ... on AnnotationTemplateTextField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateFileField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateUrlField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateDateField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateSelectField {
          id
          name
          order
          type
          isDeleted
          isVisible
          options {
            color
            id
            value
          }
        }
        ... on AnnotationTemplateImageField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
      }
      createdBy
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
    }
  }
`);

// Scene Entity
export const LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES = gql(`
  query ListSceneEntitiesByProjectIdAndRendererTypes($projectId: ID!, $rendererTypes: [String]!) {
    listSceneEntitiesByProjectIdAndRendererTypes(projectId: $projectId, rendererTypes: $rendererTypes) {
      id
      name
      position {
        x
        y
        z
      }
      rotation {
        x
        y
        z
        w
      }
      rendererType
      parentSceneEntityId
      renderObject {
        ... on KmlRenderObject {
          __typename
          id
          file {
            ... on AssetFile {
              id
              signedGetObjectUrl
            }
          }
        }
        ... on IfcRenderObject {
          __typename
          id
          epsgCode
          file {
            ... on AssetFile {
              id
              signedGetObjectUrl
              storage {
                size
              }
            }
          }
        }
        ... on DxfRenderObject {
          __typename
          id
          epsgCode
          file {
            ... on AssetFile {
              id
              signedGetObjectUrl
            }
          }
        }
        ... on Tileset3dRenderObject {
          __typename
          id
          kind
          file {
            ... on Tileset3dFile {
              id
              httpUrl
            }
          }
          process {
            ... on Tileset3dProcess {
              lastStepIsDone
            }
          }
        }
        ... on Ortho2dRenderObject {
          __typename
          id
          kind
          process {
            ... on Ortho2dProcess {
              steps {
                stepName
                comment
              }
            }
          }
          file {
            ... on Ortho2dFile {
              id
              httpUrl
            }
            ... on AssetFile {
              id
              httpUrl
            }
          }
        }
        ... on ObjRenderObject {
          __typename
          id
          epsgCode
          kind
          process {
            ... on ObjProcess {
              steps {
                stepName
                comment
              }
            }
          }
          file {
            ... on ObjFile {
              id
              httpUrl
            }
            ... on AssetFile {
              id
              httpUrl
            }
          }
        }
        ... on CesiumIonRenderObject {
          __typename
          id
          kind
          cesiumIonId
          process {
            ... on CesiumIonProcess {
              steps {
                stepName
                comment
              }
            }
          }
        }
        ... on PanoramicRenderObject {
          __typename
          id
          kind
          processId
        }
        ... on ImageProjectionRenderObject {
          __typename
          id
          kind
          processId
        }
        ... on AnnotationRenderObject {
          __typename
          id
          createdAt
        }
      }

      relativePosition
      relativeRotation
    }
  }
`);

// List the render objects by ids
export const LIST_RENDER_OBJECTS_BY_IDS = gql(`
  query ListRenderObjectsByIds($renderObjectIds: [ID]!) {
    listRenderObjectByIds(renderObjectIds: $renderObjectIds) {
      ... on PanoramicRenderObject {
        __typename
        id
        kind
        processId
        panoramicImages {
          id
          name
          relativePosition
          relativeRotation

          fileId
          file {
            ... on ImageFile {
              __typename
              fileName
              createThumbnailEvent {
                status
              }
              createTilesEvent {
                status
              }
              tiles {
                rows
                cols
              }
            }
          }
        }
      }
      ... on ImageProjectionRenderObject {
        __typename
        id
        kind
        processId
        cameraModels {
          imageSize
          principalPoint
          id
          distortion {
            k1
            k2
            k3
            p1
            p2
          }
          focalLength
          projectionString
          sensorSize
          skew
        }
        images {
          id
          cameraModelId
          cameraRelativePosition
          cameraRelativeRotation
          projectedRelativePosition
          projectedRelativeRotation

          fileId
          file {
            ... on ImageFile {
              __typename
              fileName
              createThumbnailEvent {
                status
              }
              createTilesEvent {
                status
              }
              tiles {
                rows
                cols
              }
            }
          }
        }
      }
    }
  }
`);

// Image signed URL
export const GET_IMAGE_URLS_BY_FILE_ID = gql(`
  query GetImageUrlsByFileIds($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on ImageFile {
        __typename
        id
        signedGetObjectUrl
      }
    }
  }
`);

export const GET_DOWNLOAD_URLS_BY_FILE_ID = gql(`
  query GetDownloadUrlsByFileIds($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on ImageFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on ObjFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on Ortho2dFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on Tileset3dFile {
        id
        signedGetObjectDownloadUrl
      }
    }
  }
`);

// Image signed thumbnail URL
export const GET_IMAGE_THUMBNAIL_URLS_BY_FILE_IDS = gql(`
  query GetImageThumbnailsByFileIds($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on ImageFile {
        __typename
        id
        thumbnailUrl
      }
    }
  }
`);

export const FIND_USER_BY_ACCOUNT_ID = gql(`
  query GetUserByAccountId {
    userByAccountId {
      id
      firstName
      lastName
      email
    }
  }
`);

export const DELETE_ANNOTATION_TEMPLATE = gql(`
  mutation DeleteAnnotationTemplate($annotationTemplateId: ID!) {
    deleteAnnotationTemplate(annotationTemplateId: $annotationTemplateId)
  }
`);

// Share Links
export const LIST_SHARE_LINKS_BY_PROJECT_ID = gql(`
  query ListShareLinksByProjectId($projectId: ID!) {
    listShareLinksByProjectId(projectId: $projectId) {
      id
      shareToken
      accountId
      projectId
      name
      isActivated
      createdByUserId
      updatedByUserId
      createdAt
      updatedAt
    }
  }
`);

export const LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT = gql(`
 query ListPermissionPoliciesWithAccountContext($subjectId: String, $objectId: String, $subjectType: PolicySubjectTypeInput, $objectType: PolicyObjectTypeInput, $actionType: PolicyActionTypeInput) {
    listPermissionPoliciesWithAccountContext(subjectId: $subjectId, objectId: $objectId, subjectType: $subjectType, objectType: $objectType, actionType: $actionType) {
      accountId
      subjectId
      subjectType
      objectId
      objectType
      actionType
      createdAt
      updatedAt
    }
  }
`);

export const LIST_SYSTEM_NODES_BY_PARENT_NODE_ID = gql(`
query ListSystemNodesByParentNodeId($pageSize: Int!, $pageIndex: Int!, $parentNodeId: ID, $searchTerm: String, $nodeKinds: [SystemNodeKindInput]) {
  listSystemNodesByParentNodeId(pageSize: $pageSize, pageIndex: $pageIndex, parentNodeId: $parentNodeId, searchTerm: $searchTerm, nodeKinds: $nodeKinds){
      totalNumberOfPages
      totalNumberOfFiles
      data {
        ... on FileNode {
            __typename
            id
            kind
            parentNodeId
            name
            createdAt
            createdUser {
              id
              displayName
              firstName
              lastName
            }
            lastDownloadedAt
            lastDownloadedUser {
              id
              displayName
              firstName
              lastName
            }
            fileId
        }
        ... on FolderNode {
            __typename
            id
            kind
            parentNodeId
            name
            createdAt
            createdUser {
              id
              displayName
              firstName
              lastName
            }
            lastDownloadedAt
            lastDownloadedUser {
              id
              displayName
              firstName
              lastName
            }
        }
      }
    }
  }
`);

export const PARENT_NODES_CHAIN_BY_PARENT_NODE_ID = gql(`
  query ParentNodesChainByParentNodeId($parentNodeId: ID) {
    parentNodesChainByParentNodeId(parentNodeId: $parentNodeId) {
      id
      kind
      parentNodeId
      name
      createdAt
      lastDownloadedAt
    }
  }
`);

export const FILE_SIGNED_GET_OBJECT_DOWNLOAD_URL_BY_ID = gql(`
  query FileSignedGetObjectDownloadUrlById($fileId: ID!) {
    file(fileId: $fileId) {
      ... on AssetFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on ImageFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on ObjFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on Ortho2dFile {
        id
        signedGetObjectDownloadUrl
      }
      ... on Tileset3dFile {
        id
        signedGetObjectDownloadUrl
      }
    }
  }
`);

export const LIST_USERS_BY_ACCOUNT_ID = gql(`
  query ListUsersByAccountId {
    listUsersByAccountId {
      id
      name {
        first
        last
      }
      email
    }
  }
`);

export const GET_IMAGE_FILE_BY_ID = gql(`
  query GetImageFileById($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on ImageFile {
        __typename
        fileName
        id
        kind
        originalUrl
        signedGetObjectUrl
        thumbnailUrl
      }
    }
  }
`);

export const LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListFeatureEntitlementsByAccountContext {
    listFeatureEntitlementsByAccountContext {
      enabled
      infinity
      name
      total
      type
      used
    }
  }
`);

export const GET_FILES_BY_IDS = gql(`
  query GetFilesByIds($fileIds: [ID]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        id
        signedGetObjectUrl
        signedGetObjectDownloadUrl
      }
      ... on ImageFile {
        id
        signedGetObjectUrl
        signedGetObjectDownloadUrl
      }
      ... on ObjFile {
        id
        signedGetObjectUrl
        signedGetObjectDownloadUrl
      }
      ... on Ortho2dFile {
        id
        signedGetObjectUrl
        signedGetObjectDownloadUrl
      }
      ... on Tileset3dFile {
        id
        signedGetObjectUrl
        signedGetObjectDownloadUrl
      }
    }
  }
`);

export const LIST_IMAGE_TILES_BY_FILE_IDS = gql(`
  query ListImageTilesByFileIds($fileIds: [ID]!) {
    listImageTilesByFileIds(fileIds: $fileIds) {
      fileId
      tilesWithSignedUrls {
        row
        col
        signedUrl  
      }
    }
  }
`);

export const LIST_MANUAL_PROCESSES_BY_PROJECT_ID = gql(`
  query ListManualProcessesByProjectId($projectId: ID!) {
    listManualProcessesByProjectId(projectId: $projectId) {
      ... on AnnotationProcess {
        id
        name
        __typename
        createdAt
        captureId
        accountId
        lastStep
        annotationIds
        imagesWithStatus {
          id
          status
          fileId
        }
      }
      ... on PanoramicProcess {
        id
        __typename
        createdAt
        captureId
        accountId
        lastStep
        imagesWithStatus {
          id
          status
          fileId
        }
      }
    }
  }
`);

export const ACCOUNT_V2_BY_CONTEXT = gql(`
  query AccountV2ByContext {
    readAccountByContext {
      id
      branding {
        webappBannerFileId
        webappBannerFile {
          __typename
          ... on ImageFile { originalUrl }
        }
      }
    }
  }
`);

export const CHECK_SUPER_ADMIN = gql(`
  query CheckSuperAdmin {
    checkSuperAdmin
  }
`);

export const FIND_DATA_IMPORT_REQUEST_BY_DATA_IMPORT_REQUEST_ID = gql(`
  query FindDataImportRequestByDataImportRequestId($dataImportRequestId: ID!) {
    findDataImportRequestByDataImportRequestId(id: $dataImportRequestId) {
      rootFolderImportRequestId
    } 
  }
`);

export const COUNT_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID = gql(`
  query CountFileImportRequestsByDataImportRequestId($dataImportRequestId: String!, $fileKind: String) {
    countFileImportRequestsByDataImportRequestId(
      dataImportRequestId: $dataImportRequestId
      fileKind: $fileKind
    )
  }
`);

export const FIND_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID = gql(`
  query FindFileImportRequestsByDataImportRequestId($dataImportRequestId: String!, $fileKind: String, $start: Int, $size: Int) {
    findFileImportRequestsByDataImportRequestId(
      dataImportRequestId: $dataImportRequestId
      fileKind: $fileKind
      start: $start
      size: $size
    ) {
      id
      kind
      fileId
    }
  }
`);

export const LIST_MODELS_BY_ACCOUNT_ID = gql(`
  query ListModelsByAccountId {
    listModelsByAccountId {
      id
      name
    }
  }
`);

export const LIST_TRAININGS_BY_MODEL_ID = gql(`
  query ListTrainingsByModelId($modelId: String!) {
    listTrainingsByModelId(modelId: $modelId) {
      id
      modelId
      status
      startTime
      duration
    }
  }
`);

export const READ_PROCESS_BY_ID = gql(`
  query ReadProcessById($processId: ID!) {
    readProcessById(processId: $processId) {
      ... on PanoramicProcess {
        id
        imagesWithStatus {
          fileId
          fileName
          status
        }
        formatFile {
          ... on AssetFile {
            signedGetObjectUrl
          }
        }
      }
      ... on ImageProjectionProcess {
        id
        imagesWithStatus {
          fileId
          fileName
          status
        }
        xmlFile {
          ... on AssetFile {
            signedGetObjectUrl
          }
        }
      }
    }
  }
`);
