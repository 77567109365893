import { ExpandIcon, PhotoGroupIcon } from '@/components/IconButton';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import {
  Photo,
  setViewer2DSettings,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { QuickList, QuickListAPI, QuickListItemProps } from '@skand/ui';
import { Panorama } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Thumbnail } from './Thumbnail';

export const ImageRibbon = () => {
  const photos = useViewer(state => state.ribbonPhotos);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const loadingMutex = useViewer(state => state.loadingMutex);
  const viewer2DSettings = useViewer(state => state.viewer2DSettings);

  const [, setReady] = useState(false);
  const [quickListAPI, setQuickListAPI] = useState<QuickListAPI | null>(null);

  const noUrlPhotos = useMemo(
    () => photos.filter(photo => !photo.url).map(photo => photo.id),
    [photos],
  );
  const fetchUrls = useFetchImageUrls(noUrlPhotos, false);

  const noThumbnailPhotos = useMemo(
    () => photos.filter(photo => !photo.thumbnailUrl).map(photo => photo.id),
    [photos],
  );
  const fetchThumbnailUrls = useFetchImageUrls(noThumbnailPhotos, true);

  // Handle selecting an image
  const handleSelect = async (photo: Photo) => {
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (photo.type === 'photo2D') {
        await startPhotoOverlay(photo);
      } else {
        await startPanoramaWalkthrough(photo);
      }
    }
  };

  // Enable fullscreen image viewer mode.
  const handleEnableFullScreen = async () => {
    setViewer2DSettings({ showFullScreen: true });
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (targetPhoto?.type === 'photo2D') {
        await startPhotoOverlay(targetPhoto);
      }
    }
  };

  // Disable fullscreen image viewer mode.
  const handleDisableFullScreen = async () => {
    setViewer2DSettings({ showFullScreen: false });
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (targetPhoto?.type === 'photo2D') {
        await startPhotoOverlay(targetPhoto);
      }
    }
  };

  // Fetch urls in bulk for photos in the sublist
  useEffect(() => {
    const fetchPhotoUrls = async () => {
      setReady(false);
      const urls: Map<string, string> = noUrlPhotos.length ? await fetchUrls() : new Map();
      const thumbnailUrls: Map<string, string> = noThumbnailPhotos.length
        ? await fetchThumbnailUrls()
        : new Map();

      for (const photo of photos) {
        const url = urls.get(photo.id);
        const thumbnailUrl = thumbnailUrls.get(photo.id);

        const widget = photo.widget?.getModel();
        if (url) {
          photo.url = url;
          if (widget instanceof Panorama && !photo.tileset) {
            widget.setUrl(url);
          }
        }
        if (thumbnailUrl) {
          photo.thumbnailUrl = thumbnailUrl;
          if (widget instanceof Panorama) {
            widget.setThumbnailUrl(thumbnailUrl);
          }
        }
      }
      setReady(true);
    };
    fetchPhotoUrls();
  }, [fetchThumbnailUrls, fetchUrls, noThumbnailPhotos, noUrlPhotos, photos]);

  // Scroll to selected photo
  useEffect(() => {
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (quickListAPI) {
      quickListAPI?.scrollTo(index * quickListAPI.itemSize);
    }
  }, [photos, quickListAPI, targetPhoto]);

  // Handler for keyboard input
  const onKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const { key } = event;
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (index < 0) {
      return;
    } else if (key === 'ArrowUp' && index > 0) {
      handleSelect(photos[index - 1]);
    } else if (key === 'ArrowDown' && index < photos.length - 1) {
      handleSelect(photos[index + 1]);
    }
  };

  // List item component wrapper
  const ListItem = useCallback(
    ({ index }: QuickListItemProps) => {
      const photo = photos[index];
      return (
        <div key={`photo-${index}`}>
          <Thumbnail photo={photo} />
        </div>
      );
    },
    [photos],
  );

  return (
    <div className="h-full min-w-400px flex flex-col bg-neutral-100 pl-18px pr-18px">
      {targetPhoto?.type === 'photo2D' && (
        <div className={cn('absolute right-3 top-3 flex flex-row')}>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !viewer2DSettings.showFullScreen ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-0px',
              'border-t-1',
              'border-b-1',
              'rounded-bl-1',
              'rounded-tl-1',
              'border-solid',
              'fill-none',
              'border-neutral-400',
            )}
            disabled={viewer2DSettings.showFullScreen}
            onClick={handleEnableFullScreen}
          >
            <ExpandIcon
              className={cn(
                !viewer2DSettings.showFullScreen ? 'color-neutral-400' : 'color-neutral-100',
              )}
            />
          </button>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              viewer2DSettings.showFullScreen ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-1',
              'border-t-1',
              'border-b-1',
              'rounded-br-1',
              'rounded-tr-1',
              'border-solid',
              'fill-none',
              'border-neutral-400',
            )}
            disabled={!viewer2DSettings.showFullScreen}
            onClick={handleDisableFullScreen}
          >
            <PhotoGroupIcon
              className={cn(
                viewer2DSettings.showFullScreen ? 'color-neutral-400' : 'color-neutral-100',
              )}
            />
          </button>
        </div>
      )}
      <div className="mt-30px flex justify-between border-1 b-neutral-300 border-b-solid pb-8px">
        <p className={cn('typo-heading-4 color-neutral-800')}>Image viewer</p>
      </div>
      <div className={cn('h-full overflow-scroll w-full mt-2')} onKeyDown={onKeyDown} tabIndex={0}>
        <QuickList itemCount={photos.length} ref={setQuickListAPI}>
          {ListItem}
        </QuickList>
      </div>
    </div>
  );
};
