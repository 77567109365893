import { DownloadIcon, IconButton, PopupIcon } from '@/components/IconButton';
import { FinishedTag } from '@/components/ProcessTab/FinishedTag';
import { IgnoredTag } from '@/components/ProcessTab/IgnoredTag';
import { InProgressTag } from '@/components/ProcessTab/InProgressTag';
import { useShareLink } from '@/stores/shareLink';
import {
  Photo,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { downloadBlob } from '@/utils/download';
import { Progress, toast } from '@skand/ui';
import { useEffect, useState } from 'react';

interface ThumbanailProps {
  photo: Photo;
}

export const Thumbnail = ({ photo }: ThumbanailProps) => {
  const targetPhoto = useViewer(state => state.targetPhoto);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const loadingMutex = useViewer(state => state.loadingMutex);

  const selectedPhotoGroupIds = useShareLink(state => state.selectedPhotoGroupIds);
  const selectedImageIdByImageViewer = useShareLink(state => state.selectedImageIdByImageViewer);
  const selectedImageIdByLayers = useShareLink(state => state.selectedImageIdByLayers);
  // Process related states
  const targetProcess = useViewer(state => state.targetProcess);
  const processImage = targetProcess?.images.get(photo.id);

  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);

  // Fetch the url and download the blob to render the thumbnail image
  useEffect(() => {
    const downloadThumbnail = async () => {
      try {
        // Fallback to the main url if the thumbnail is not available
        let url = photo.thumbnailUrl;
        if (!url) {
          url = photo.url;
        }
        if (url) {
          const imgBlob = await downloadBlob(url, setDownloadProgress);
          setObjectUrl(window.URL.createObjectURL(imgBlob));
        }
      } catch (error) {
        toast({ type: 'warn', message: `${error}`, lifespan: 10000 });
      }
    };
    downloadThumbnail();
  }, [photo.thumbnailUrl, photo.url]);

  // Handle downloading the image
  const handleDownload = async () => {
    if (photo.url) {
      window.open(photo.url);
    }
  };

  // Handle opening the image popup
  const handlePopup = async () => {
    useViewer.setState(prev => {
      const popupPhotos = new Set(prev.popupPhotos);
      popupPhotos.add(photo);
      return { popupPhotos };
    });
  };

  // Handle selecting the image
  const handleSelect = async () => {
    if (!loadingMutex) {
      if (selectedPhotoGroupIds) {
        useShareLink.setState({ selectedImageIdByImageViewer: photo.id });
      }
      stopPhotoOverlay();
      stopPanoramaView();
      if (photo.type === 'photo2D') {
        await startPhotoOverlay(photo);
      } else {
        await startPanoramaWalkthrough(photo);
      }
    }
  };

  // List annotations associated with the photo
  const annotations = annotationGroups
    .flatMap(group => group.annotations)
    .filter(annotation => annotation.photo === photo);
  const isSketch2DAvailable = annotations.length > 0;
  const isSketch3DAvailable = annotations.find(annotation => annotation.sketch3D);
  const isTargetPhotoLoading = downloadProgress > 0 && downloadProgress < 1 && objectUrl === null;

  return (
    <div className="mb-3">
      <div
        className={cn(
          'w-full',
          'h-200px',
          'bg-zinc-800',
          'relative',
          'mb-1',
          'box-border',
          'b-rounded-1',
          'overflow-hidden',
          'b-primary-400',
          'b-2px',
          (targetPhoto === photo ||
            selectedImageIdByImageViewer === photo.id ||
            selectedImageIdByLayers === photo.id) &&
            'b-solid',
        )}
      >
        {isTargetPhotoLoading && (
          <div
            className={cn('flex', 'h-full', 'absolute', 'w-full', 'justify-center', 'items-center')}
          >
            <Progress className="w-153px" progress={downloadProgress * 100} />
          </div>
        )}
        <div
          className={cn(
            'flex',
            'justify-between',
            'p-8px',
            'absolute',
            'w-full',
            'pointer-events-none',
          )}
        >
          <div className={cn('flex', 'justify-between')}>
            {processImage && (
              <div className="mr-8px">
                {processImage.status === 'created' && <InProgressTag />}
                {processImage.status === 'checked' && <FinishedTag />}
                {processImage.status === 'ignored' && <IgnoredTag />}
              </div>
            )}
            {isSketch2DAvailable && (
              <div
                className={cn(
                  'i-skand-annotation2doutlined',
                  'text-16px',
                  'mr-8px',
                  'text-neutral-400',
                )}
              />
            )}
            {isSketch3DAvailable && (
              <div
                className={cn('i-skand-annotation3doutlined', 'text-16px', 'text-neutral-400')}
              />
            )}
          </div>
          <div className="flex flex-row">
            <IconButton
              buttonIcon={<PopupIcon />}
              buttonState="active"
              className={cn(
                'hover:cursor-pointer',
                'border-0',
                'bg-transparent',
                'pointer-events-auto',
              )}
              onClick={handlePopup}
            />
            <IconButton
              buttonIcon={<DownloadIcon />}
              buttonState="active"
              className={cn(
                'hover:cursor-pointer',
                'border-0',
                'bg-transparent',
                'pointer-events-auto',
              )}
              onClick={handleDownload}
            />
          </div>
        </div>
        {objectUrl && (
          <img
            className={cn('w-full', 'h-full', 'object-cover', 'cursor-pointer')}
            onClick={handleSelect}
            src={objectUrl}
          />
        )}
      </div>
      <div
        className={cn(
          'typo-text-small',
          'color-neutral-9',
          'text-ellipsis',
          'overflow-hidden',
          'whitespace-nowrap',
        )}
      >
        {photo.group.name} | {photo.name}
      </div>
    </div>
  );
};
